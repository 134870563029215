//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import { isProductType } from '@motionelements/core/src/services/catalog.service.js';
import channelTypesMixin from '@motionelements/core/src/components/mixins/channelTypes.mixin.js';

export default {
  name: 'HeroSearchBar',
  mixins: [channelTypesMixin],
  components: {
    SearchBar: () => import('./SearchBar.vue'),
    FileSearchWrapper: () => import('@motionelements/core/src/components/ui/search-box/FileSearchWrapper.vue'),
  },
  computed: {
    ...mapState({
      channelType: state => state.channels.channelType,
    }),
    heading() {
      return isProductType('audio', this.channelType)
        ? this.$t('search.audio_search')
        : this.$t('search.visual_search');
    },
  },
};
